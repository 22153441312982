import React from "react";
import DatePicker from "react-datepicker";
import { Label } from "components/typography";
import { multipleClassNames } from "shared/utilities";
import styles from "./Input.module.scss";
import "react-datepicker/dist/react-datepicker.css";

export const Input = ({ className = [], label, formik = {}, type = "text", name = "", disabled, ...props }) => {
  const style = [styles.input, ...className];

  const handleDateChange = (date) => {
    if (!date) {
      formik.setFieldValue(name, "");
      return;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    formik.setFieldValue(name, formattedDate);
  };

  const fieldValue = type === "date" && formik.values[name] ? new Date(formik.values[name]) : formik.values[name];

  // Calculate dynamic minDate and maxDate
  const fromDate = formik.values.from_date ? new Date(formik.values.from_date) : null;
  const toDate = formik.values.to_date ? new Date(formik.values.to_date) : null;

  const minDate = name === "to_date" ? fromDate : toDate ? new Date(toDate.getTime() - 14 * 24 * 60 * 60 * 1000) : null;

  const maxDate = name === "from_date" ? toDate : fromDate ? new Date(fromDate.getTime() + 14 * 24 * 60 * 60 * 1000) : null;

  return (
    <Label label={label} disabled={disabled}>
      {type === "date" ? (
        <DatePicker
          className={multipleClassNames(style)}
          selected={fieldValue}
          wrapperClassName="w-full"
          onChange={handleDateChange}
          dateFormat="dd/MM/yyyy" // Desired display format
          minDate={minDate} // Dynamic minDate
          maxDate={maxDate} // Dynamic maxDate
          disabled={disabled}
          placeholderText="Select a date - dd/mm/yyyy"
          {...props}
        />
      ) : (
        <input className={multipleClassNames(style)} type={type} name={name} onChange={formik.handleChange} value={fieldValue} disabled={disabled} {...props} />
      )}
    </Label>
  );
};
