import { createAsyncThunk } from "@reduxjs/toolkit";
import { arrayRemove, arrayUnion, collection, doc, getDocs, orderBy, query, updateDoc } from "firebase/firestore";
import { nanoid } from "nanoid";

import { firestoreDB } from "shared/config";
import { ENDPOINTS, NOTIFICATION_TYPE } from "shared/constants";
import { addNotifications } from "../slices";

export const getData = createAsyncThunk("data/getData", async (payload, { dispatch }) => {
  try {
    const d = await getDocs(collection(firestoreDB, ENDPOINTS.data));
    const [r] = d.docs.map((doc) => ({ id: doc?.id, ...doc.data() }));
    return r;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not retrieve data. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const getClubs = createAsyncThunk("data/getClub", async (payload, { dispatch }) => {
  try {
    // Create a query to sort clubs by the "name" field
    const clubsQuery = query(collection(firestoreDB, ENDPOINTS.clubs), orderBy("name"));
    const d = await getDocs(clubsQuery);

    const clubs = d.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return clubs; // Return the sorted array
  } catch (error) {
    console.error("Error retrieving clubs:", error);
    dispatch(
      addNotifications({
        id: nanoid(),
        text: "Could not retrieve clubs. Try again later.",
        type: NOTIFICATION_TYPE.error,
      })
    );
    return [];
  }
});

export const addData = createAsyncThunk("data/addData", async (payload, { dispatch }) => {
  try {
    const { id, endpoint, value } = payload;
    await updateDoc(doc(firestoreDB, ENDPOINTS.data, id), { [endpoint]: arrayUnion(value) }, { merge: true });
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not add data. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const removeData = createAsyncThunk("data/removeData", async (payload, { dispatch }) => {
  try {
    const { id, endpoint, value } = payload;
    await updateDoc(doc(firestoreDB, ENDPOINTS.data, id), { [endpoint]: arrayRemove(value) }, { merge: true });
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Could not delete data. Try again later.", type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const dataReducers = (builder) => {
  builder.addCase(getData.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getData.fulfilled, (state, { payload }) => {
    state.id = payload?.id;
    state.clubs = payload?.clubs?.sort();
    state.agencies = payload?.agencies?.sort();
    state.venues = payload?.venues?.sort();
    state.isLoading = false;
  });
  builder.addCase(getData.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(getClubs.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getClubs.fulfilled, (state, { payload }) => {
    state.clubList = payload;
    state.isLoading = false;
  });
  builder.addCase(getClubs.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(addData.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(addData.fulfilled, (state, { payload }) => {
    const { endpoint, value } = payload;
    state[endpoint] = [...state[endpoint], value]?.sort();
    state.isLoading = false;
  });
  builder.addCase(addData.rejected, (state) => {
    state.isLoading = false;
  });

  builder.addCase(removeData.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(removeData.fulfilled, (state, { payload }) => {
    const { endpoint, value } = payload;
    const list = state[endpoint].filter((item) => item !== value);
    state[endpoint] = list?.sort();
    state.isLoading = false;
  });
  builder.addCase(removeData.rejected, (state) => {
    state.isLoading = false;
  });
};
