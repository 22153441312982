import React from "react";
import { multipleClassNames } from "shared/utilities";
import styles from "./BoxCell.module.scss";

export const BoxCell = ({ label, value, fullwidth = false }) => {
  const style = [styles.boxcell];

  if (fullwidth) {
    style.push(styles.boxcell__fullwidth);
  }

  return (
    <div className={multipleClassNames(style)}>
      <span className={styles.boxcell__label}>{label}</span>
      <span className={styles.boxcell__cell}>{value}</span>
    </div>
  );
};
