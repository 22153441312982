import React from "react";
import ReactDOM from "react-dom/client";

import "styles/css/normalize.css";
import "styles/css/globals.css";

import AppRouter from "containers/AppRouter";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppRouter />
  // </React.StrictMode>
);

reportWebVitals();
