import { nanoid } from "nanoid";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { child, get, limitToLast, orderByChild, query as databaseQuery, ref, set, update } from "firebase/database";

import { firestoreDB, realtimeDB } from "shared/config";
import { addNotifications } from "../slices";
import { ARCHIVED_SEASONS, ENDPOINTS, NOTIFICATION_TYPE } from "shared/constants";
import { findStringInObject, requestLocally, requestTransformedDataLocally, sortStringsList } from "shared/utilities";
import { collection, getDocs, query, where } from "firebase/firestore";

let dataList = {};

export const getReports = createAsyncThunk("reports/getReports", async () => {
  const q = query(collection(firestoreDB, "reports"), where("season", "in", ARCHIVED_SEASONS));

  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    dataList[doc.id] = doc.data();
  });

  const dbRef = ref(realtimeDB);
  const d = await get(child(dbRef, ENDPOINTS.reports));
  const r = d?.exists() && d?.val();
  // const y2015 = await requestTransformedDataLocally("2015_2016");
  // const y2016 = await requestTransformedDataLocally("2016_2017");
  // const y2017 = await requestTransformedDataLocally("2017_2018");
  // const y2018 = await requestTransformedDataLocally("2018_2019");
  // const y2019 = await requestTransformedDataLocally("2019_2020");
  // const y2020 = await requestTransformedDataLocally("2020_2021");
  // const y2021 = await requestTransformedDataLocally("2021_2022");
  // const y2022 = await requestTransformedDataLocally("2022_2023");

  // return { ...r, ...y2022, ...y2021, ...y2020, ...y2019, ...y2018, ...y2017, ...y2016, ...y2015 };

  return { ...r, ...dataList };
});

export const getLatestReports = createAsyncThunk("reports/getLatestReports", async () => {
  try {
    const reportsRef = ref(realtimeDB, ENDPOINTS.reports);
    const reportsQuery = databaseQuery(reportsRef, orderByChild("event_date"), limitToLast(10));

    const snapshot = await get(reportsQuery);

    if (snapshot.exists()) {
      const data = snapshot.val();

      const latestReports = Object.values(data).sort((a, b) => new Date(b.event_date) - new Date(a.event_date));

      return latestReports;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
});

export const addReport = createAsyncThunk("reports/addReport", async (payload, { dispatch }) => {
  try {
    const id = payload?.id;
    const dbRef = ref(realtimeDB, `${ENDPOINTS.reports}/${id}`);

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      await update(dbRef, payload);
      dispatch(addNotifications({ id: nanoid(), text: `Report updated successfully`, type: NOTIFICATION_TYPE.success }));
    } else {
      await set(dbRef, payload);
      dispatch(addNotifications({ id: nanoid(), text: `Report created successfully`, type: NOTIFICATION_TYPE.success }));
    }
    return payload;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: `Failed to add/update report, contact your administrator.`, type: NOTIFICATION_TYPE.error }));
    return null;
  }
});

export const getReportsByFilter = createAsyncThunk("reports/getReportsByFilter", async (payload, { dispatch }) => {
  try {
    let localReportsList = [];

    if (payload?.season?.length === 0) {
      // Load data from firebase as well
      const dbRef = ref(realtimeDB);
      const d = await get(child(dbRef, ENDPOINTS.reports));
      const r = d?.exists() && d?.val();
      let reportList = {};

      const q = query(collection(firestoreDB, "reports"), where("season", "in", ARCHIVED_SEASONS));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        reportList[doc.id] = doc.data();
      });
      localReportsList = [...Object.values(r), ...Object.values(reportList)];
    }

    if (ARCHIVED_SEASONS.includes(payload?.season) && payload?.season?.length > 0) {
      const [selectedYear] = payload?.season?.split("/");

      let archivedList = [];

      if (ARCHIVED_SEASONS.includes(Number(selectedYear) - 1)) {
        const pastYear = await requestLocally(`${Number(selectedYear) - 1}/${selectedYear}`);
        archivedList.push(pastYear);
      }

      if (ARCHIVED_SEASONS.includes(Number(selectedYear) + 1)) {
        const nextYear = await requestLocally(`${selectedYear}/${Number(selectedYear) + 1}`);

        archivedList.push(nextYear);
      }

      const yearSeason = payload?.season?.replace("/", "_");

      let thisYear = {};

      const q = query(collection(firestoreDB, "reports"), where("season", "==", payload.season));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        thisYear[doc.id] = doc.data();
      });
      const thisYearResult = Object.values(thisYear);

      localReportsList = [...archivedList, thisYearResult].flat();

      // const oldLocalReportList = [...archivedList, thisYearOld].flat();
    }

    if (!ARCHIVED_SEASONS.includes(payload?.season) && payload?.season?.length > 0) {
      const dbRef = ref(realtimeDB);
      const d = await get(child(dbRef, ENDPOINTS.reports));
      localReportsList = d?.exists() && d?.val();
    }

    const filteredReports = filterReports(localReportsList, payload);
    if (filteredReports.length === 0) {
      dispatch(addNotifications({ id: nanoid(), text: "No matching records", type: NOTIFICATION_TYPE.success }));
      return ["No matching records"];
    }

    return filteredReports;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Failed to retrieve data from cloud, try again later.", type: NOTIFICATION_TYPE.error }));
  }
});

const filterReports = (localReportsList, payload) => {
  if (Array.isArray(localReportsList)) {

    const fil = localReportsList.filter((record) => {

      const home_team_UPDATED = record?.home_team?.replace(/FC$/, "").trim().toLowerCase();
      const away_team_UPDATED = record?.away_team?.replace(/FC$/, "").trim().toLowerCase();

      if (record?.home_team) {
        return (
          (payload.home === "" || home_team_UPDATED === payload.home.replace(/FC$/, "").trim().toLowerCase()) &&
          (payload.away === "" || away_team_UPDATED === payload.away.replace(/FC$/, "").trim().toLowerCase()) &&
          (payload.season === "" || record.season === payload.season) &&
          (payload.competition === "" || record.competition === payload.competition) &&
          // (isNaN(fromDate) || isNaN(toDate) || (fromDate <= (record.event_date * 1000) && (record.event_date * 1000) <= toDate)) &&
          (payload.agency === "" || record.agency_list.includes(payload.agency)) &&
          (payload.keyword === "" || findStringInObject(record, payload.keyword))
        );
      }
    });
    return fil;
  } else {
    const filteredKeys = Object.keys(localReportsList).filter((item) => {
      const record = localReportsList[item];
      // const isTypeMatch = payload.type === "" || payload.type === record.type;
      const isAgencyMatch = payload.agency === "" || payload.agency === record.agency;
      const isCompetitionMatch = payload.competition === "" || payload.competition === record.competition;
      const isHomeMatch = payload.home === "" || payload.home === record.home_team;
      const isAwayMatch = payload.away === "" || payload.away === record.away_team;
      const isSeasonMatch = payload.season === "" || payload.season === record.season;
      const isKeyword = payload.keyword === "" || findStringInObject(record, payload.keyword);

      return isAgencyMatch && isCompetitionMatch && isHomeMatch && isAwayMatch && isSeasonMatch && isKeyword;
    });

    return filteredKeys.map((item) => localReportsList[item]);
  }
};

export const getReportById = createAsyncThunk("reports/getReportById", async (payload, { dispatch }) => {
  try {
    if (!payload?.pid) return;

    const y2015 = await requestLocally("2015_2016");
    const testFor2015 = y2015?.find((report) => report?.id === payload?.pid);

    if (testFor2015) {
      return testFor2015;
    }

    const y2016 = await requestLocally("2016_2017");
    const testFor2016 = y2016?.find((report) => report?.id === payload?.pid);

    if (testFor2016) {
      return testFor2016;
    }

    const y2017 = await requestLocally("2017_2018");
    const testFor2017 = y2017?.find((report) => report?.id === payload?.pid);

    if (testFor2017) {
      return testFor2017;
    }

    const y2018 = await requestLocally("2018_2019");
    const testFor2018 = y2018?.find((report) => report?.id === payload?.pid);

    if (testFor2018) {
      return testFor2018;
    }

    const y2019 = await requestLocally("2019_2020");
    const testFor2019 = y2019?.find((report) => report?.id === payload?.pid);

    if (testFor2019) {
      return testFor2019;
    }

    const y2020 = await requestLocally("2020_2021");
    const testFor2020 = y2020?.find((report) => report?.id === payload?.pid);

    if (testFor2020) {
      return testFor2020;
    }

    const y2021 = await requestLocally("2021_2022");
    const testFor2021 = y2021?.find((report) => report?.id === payload?.pid);

    if (testFor2021) {
      return testFor2021;
    }

    const y2022 = await requestLocally("2022_2023");
    const testFor2022 = y2022?.find((report) => report?.id === payload?.pid);

    if (testFor2022) {
      return testFor2022;
    }

    const dbRef = ref(realtimeDB);
    const d = await get(child(dbRef, ENDPOINTS.reports));
    const r = d?.exists() && d?.val();

    const testForLive = r?.find((report) => report?.id === payload?.pid);

    return testForLive;
  } catch (error) {
    console.error(error);
    dispatch(addNotifications({ id: nanoid(), text: "Failed to retrieve data from cloud, try again later.", type: NOTIFICATION_TYPE.error }));
  }
});

export const reportsReducers = (builder) => {
  // Helper function to update isLoading state
  const setLoadingState = (state, isLoading) => {
    state.isLoading = isLoading;
  };

  // Helper function to update filtered state
  const setFilteredState = (state, isLoading, data) => {
    state.isLoading = isLoading;
    state.filtered = data;
  };

  builder
    .addCase(getReports.pending, (state) => {
      setLoadingState(state, true);
    })
    .addCase(getReports.fulfilled, (state, { payload }) => {
      setLoadingState(state, false);

      state.list = Object.values(payload);
      state.lastUpdate = new Date().getTime();
    })
    .addCase(getReports.rejected, (state) => {
      setLoadingState(state, false);
    })
    .addCase(getLatestReports.pending, (state) => {
      setLoadingState(state, true);
    })
    .addCase(getLatestReports.fulfilled, (state, { payload }) => {
      setLoadingState(state, false);

      state.latestList = Object.values(payload);
      state.lastUpdate = new Date().getTime();
    })
    .addCase(getLatestReports.rejected, (state) => {
      setLoadingState(state, false);
    })
    .addCase(getReportsByFilter.pending, (state) => {
      setFilteredState(state, true, []);
    })
    .addCase(getReportsByFilter.fulfilled, (state, { payload }) => {
      setFilteredState(state, false, sortStringsList(payload, "season").reverse());
    })
    .addCase(getReportsByFilter.rejected, (state) => {
      setFilteredState(state, false, []);
    })
    .addCase(getReportById.pending, (state) => {
      state.currentReport = {};
      setFilteredState(state, true, []);
    })
    .addCase(getReportById.fulfilled, (state, { payload }) => {
      setLoadingState(state, false);
      state.currentReport = payload;

      if (payload) {
        state.filtered = [payload];
      }
    })
    .addCase(getReportById.rejected, (state) => {
      setLoadingState(state, false);
    })
    .addCase(addReport.pending, (state) => {
      setLoadingState(state, true);
    })
    .addCase(addReport.fulfilled, (state, { payload }) => {
      setLoadingState(state, false);

      const existingReportIndex = state.list.findIndex((report) => report.id === payload.id);

      if (existingReportIndex !== -1) {
        state.list[existingReportIndex] = payload;
      } else {
        state.list = [...state.list, payload];
      }
    })

    .addCase(addReport.rejected, (state) => {
      setLoadingState(state, false);
    });
};
